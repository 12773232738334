<template>
    <div class="ma-5">

        <ts-estudios/>
    </div>
</template>

<script>
export default {
    components:{
        'ts-estudios': () => import('@/components/tsocial/EstudioSe'),
    },

}
</script>

<style>

</style>